.paymentContainer {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 26px 0;
    &_heading {
        color: #707070;
        text-align: center;
        font-weight: 500;
        font-size: 30px;
        margin: 18px 0;
    }
    &_card {
        background-color: white;
        width: 60%;
        border: 1px solid #666666;
        padding: 42px 0;
        position: relative;
        &-header {
            text-align: center;
            h1 {
                color: #7e7e7e;
                margin-bottom: 2px;
            }
            p {
                color: #5a5a5a;
                font-size: 17px;
            }
        }
        &-body {
            display: flex;
            width: 100%;
            margin: 0 auto;
            justify-content: space-around;
            .paymentDetails_col {
                width: 40%;
                .address-container {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    & > .paymentDetails_col_field-container {
                        width: 45%;
                    }
                }
                .payment-container {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    & > .paymentDetails_col_field-container {
                        width: 70%;
                    }
                    &_icon {
                        width: 25%;
                        border: 0.5px solid #7dabfc;
                        height: 36px;
                        border-radius: 3px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-top: 2px;
                        &> img{
                            height: 36px;
                        }
                    }
                }
                &-heading {
                    color: #5a5a5a;
                    font-weight: 500;
                    font-size: 17px;
                    margin-bottom: 12px;
                }

                &_field-container {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 16px;
                    & > .label {
                        color: #5a5a5a;
                        font-size: 15px;
                        -webkit-text-stroke: 0.025em rgba(51, 51, 51, 0.5);
                        margin-bottom: 3px;
                    }
                    & > .textField {
                        height: 36px;
                        border-radius: 3px;
                        border: 1.5px solid #7dabfc;
                        outline: 0;
                        padding: 0 24px 0 17px;
                        font-size: 16px;
                    }
                }
                .cvv-field{
                    width: 50%;
                }
            }
        }
        &-footer {
            display: flex;
            justify-content: flex-end;
            padding: 0 20px;
            .signUp-actions {
                width: 230px;
                display: flex;
                justify-content: space-between;
                &_btn {
                    background: white;
                    width: 100px;
                    height: 33px;
                    border: 1px solid #7c7c7c;
                    color: #707070;
                    font-size: 15px;
                    -webkit-text-stroke: 0.025em rgba(51, 51, 51, 0.5);
                    border-radius: 3px;
                    box-shadow: 2px 2px 3px;
                    cursor: pointer;
                    outline: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
}
