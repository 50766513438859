*{
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
.signInContainer, .signUpContainer, .paymentContainer{
  background-image: url("./assets/PPMone Login Image.png");
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  image-rendering: -webkit-optimize-contrast
}
.loader {
  border: 4px solid #dad5d5;
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 10px;
  height: 10px;
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}