.menu-container {
    background: white;
    min-width: 225px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .navigation-link {
        height: 50px;
        display: flex;
        align-items: center;
        padding-left: 15px;
        cursor: pointer;
        color: #5a5a5a;
        text-transform: uppercase;
        font-size: 14px;
        -webkit-text-stroke: 0.025em rgba(51, 51, 51, 0.5);
        text-decoration: none;
        
    }
}
