.widget-footer {
    display: flex;
    justify-content: flex-end;
    height: 55px;
    align-items: center;
    position: absolute;
    width: 100%;
    bottom: 0;
    .widget-footer_actions {
        display: flex;
        &--btn {
            background: white;
            width: 100px;
            height: 33px;
            border: 1px solid #7c7c7c;
            color: #707070;
            font-size: 15px;
            -webkit-text-stroke: 0.025em rgba(51, 51, 51, 0.5);
            border-radius: 3px;
            box-shadow: 2px 2px 3px;
            cursor: pointer;
            outline: none;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 6px;
        }
    }
}

