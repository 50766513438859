.add-project-widget-container {
    position: relative;
    height: inherit;
    &_row {
        display: flex;
        border-bottom: 1px dashed #707070;
    }
    .project-widget_col {
        border-right: 1px dashed #707070;
        &.width-60 {
            width: 60%;
        }
        &.width-40 {
            border-right: none;

            width: 40%;
        }
        .project-info {
            padding-right: 12px;
            min-height: 240px;
        }
        .project-budget {
            height: 156px;
            border-top: 1px dashed #707070;
            display: flex;
            align-items: center;
            display: flex;
            &_col {
                width: 50%;
                padding: 15px 0;
                &--heading {
                    color: #5a5a5a;
                    text-transform: uppercase;
                    font-size: 15px;
                    -webkit-text-stroke: 0.025em rgba(51, 51, 51, 0.5);
                    margin-bottom: 26px;
                }
                .field-row--label {
                    width: 50%;
                }
                .field-row--label {
                    span {
                        font-size: 13px;
                    }
                }
            }
        }
    }
    .project-schedule {
        padding: 0 18px;
        .field-row--label {
            span {
                font-size: 13px;
            }
        }
        &_heading {
            color: #5a5a5a;
            text-transform: uppercase;
            font-size: 15px;
            -webkit-text-stroke: 0.025em rgba(51, 51, 51, 0.5);
            margin-bottom: 26px;
        }
    }
    .forecast-burndown {
        padding: 10px 18px;
        border-top: 1px dashed #707070;
        &_heading {
            color: #5a5a5a;
            text-transform: uppercase;
            font-size: 15px;
            -webkit-text-stroke: 0.025em rgba(51, 51, 51, 0.5);
            margin-bottom: 26px;
        }
        &_row {
            align-items: center;
            position: relative;
            max-height: 232px;
            overflow-y: auto;
            &::-webkit-scrollbar {
                width: 4px;
                &-track {
                    background: #f1f1f1;
                }
                &-thumb {
                    background: #888;
                    &:hover {
                        background: #555;
                    }
                }
            }

            &--col {
                &-1 {
                    width: 80%;
                }
                &-2 {
                    width: 20%;
                    position: absolute;
                    right: 0;
                    top: 10px;
                    button {
                        background: white;
                        width: 80px;
                        height: 28px;
                        border: 1px solid #7c7c7c;
                        color: #707070;
                        font-size: 15px;
                        -webkit-text-stroke: 0.025em rgba(51, 51, 51, 0.5);
                        border-radius: 3px;
                        box-shadow: 2px 2px 3px;
                        cursor: pointer;
                        outline: none;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: fixed;
                    }
                }
            }
        }
        &_date {
            width: 40%;
            display: flex;
            align-items: center;
            span {
                font-size: 13px;
                color: #5a5a5a;
                text-transform: uppercase;
                -webkit-text-stroke: 0.025em rgba(51, 51, 51, 0.5);
            }
        }
        &_field {
            width: 30%;
            margin: 0 8px;
            input {
                width: 100%;
                border: none;
                height: 28px;
                outline: none;
                color: #5a5a5a;
                font-size: 14px;
                -webkit-text-stroke: 0.015em rgba(51, 51, 51, 0.5);
            }
        }
    }
}
.field-row {
    display: flex;
    margin: 10px 0;
    align-items: center;
    &.description {
        align-items: unset;
    }
    &--label {
        width: 35%;
        span {
            color: #5a5a5a;
            text-transform: uppercase;
            font-size: 15px;
            -webkit-text-stroke: 0.025em rgba(51, 51, 51, 0.5);
        }
    }
    &--input {
        width: 65%;
        
        position: relative;
        &.small {
            width: 134px;
        }
        input[type="radio"] {
            height: 20px;
            width: 20px;
        }
        input,
        textarea,
        select {
            width: 100%;
            border: none;
            height: 28px;
            outline: none;
            color: #5a5a5a;
            font-size: 14px;
            -webkit-text-stroke: 0.015em rgba(51, 51, 51, 0.5);
            padding: 0 6px;
            box-sizing: border-box;
        }
        textarea {
            height: 120px;
            resize: none;
        }
        &.date-picker-row {
            display: flex;
            align-items: center;

            .date-picker {
                overflow: hidden;
                width: 35px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: transparent;
                &:focus {
                    outline: none;
                }
            }
            .display-date {
                outline: none;
                color: #5a5a5a;
                font-size: 14px;
                -webkit-text-stroke: 0.015em rgba(51, 51, 51, 0.5);
                background: white;
                height: 28px;
                display: flex;
                align-items: center;
                width: inherit;
                padding-left: 8px;
            }
        }
        input[type="date" i]::-webkit-calendar-picker-indicator {
            background-size: 16px;
            padding: 0 !important;
            margin: 0 !important;
            &:focus {
                outline-offset: 0 !important;
                outline: none !important;
            }
        }
        &_currency{
            position: absolute;
            top: 6px;
            color: #5a5a5a;
            font-size: 14px;
            -webkit-text-stroke: 0.015em rgba(51, 51, 51, 0.5);
            left: 5px;
        }
    }
}
