.projects-container {
    display: grid;
    padding-left: 0px;
    grid-template-columns: repeat(3, 1fr);
    // grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    grid-gap: 20px;
}
.project-card {
    background: white;
    min-width: 300px;
    padding: 14px 0px 10px 0;
    min-height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    margin: 0 auto;
    width: 100%;
    cursor: pointer;
    span {
        color: #707070;
        font-size: 15px;
    }
    &--name {
        padding: 0 8px;
        &-border {
            background: #055bf5;
            height: 3px;
            width: 35px;
            margin-top: 5px;
        }
    }
    &_row {
        display: flex;
        &-col {
            width: 50%;
            padding: 0 8px;
        }
    }
    &--info {
        margin: 8px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &_value {
            font-size: 14px !important;
        }
        .project-state {
            width: 14px;
            height: 14px;
            border-radius: 100%;
            &.ontrack {
                background: #43e0aa;
            }
            &.offtrack {
                background: #de1f44;
            }
            &.atrisk{
                background: #f5b55d;
            }
            // &.new {
            //     background: #43e0aa;
            // }
            // &.inprogress {
            //     background: #f5b55d;
            // }
            // &.onhold {
            //     background: #de1f44;
            // }
        }
    }
}
