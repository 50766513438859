.checklist {
    &-item-row {
        display: flex;
        align-items: center;
        margin: 8px 0;
        cursor: pointer;
        &_circle {
            height: 18px;
            min-width: 18px;
            border: 1px solid #055bf5;
            border-radius: 100%;
            margin-right: 10px;
            &.checked {
                transform: scaleX(-1) rotate(-40deg);
                color: #055bf5;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        &_text {
            color: #5a5a5a;
            font-size: 16px;
            -webkit-text-stroke: 0.025em rgba(51, 51, 51, 0.5);
            &.completed {
                text-decoration: line-through;
            }
        }
        &--contain {
            width: inherit;
        }
    }
}
.project-updates {
    background: white;
    // max-height: 300px;
    // height: 300px;

    padding: 20px 15px;
    box-sizing: border-box;
    &_info {
        &--date {
            width: fit-content;
            margin: 0 0 0 auto;
            margin-bottom: 6px;
        }
        p {
            color: #5a5a5a;
            text-transform: capitalize;
            font-size: 14px;
            -webkit-text-stroke: 0.025em rgba(51, 51, 51, 0.5);
        }
    }
}
