.project-detail {
    position: relative;
    height: 100%;
    &-row {
        display: flex;
        &--heading {
            color: #5a5a5a;
            text-transform: uppercase;
            font-size: 15px;
            -webkit-text-stroke: 0.025em rgba(51, 51, 51, 0.5);
            margin: 12px 0;
        }
        &_col {
            &-30 {
                width: 30%;
            }
            &-40 {
                width: 40%;
            }
            &-30,
            &-40 {
                box-sizing: border-box;
                padding: 0 8px;
            }
        }
    }
}
.scrollable {
    max-height: 300px;
    height: 300px;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 4px;
        &-track {
            background: #f1f1f1;
        }
        &-thumb {
            background: #888;
            &:hover {
                background: #555;
            }
        }
    }
}
