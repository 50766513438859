.signInContainer {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    &_card {
        background-color: white;
        width: 460px;
        border: 1px solid #666666;
        padding: 42px 0;
        &-header {
            text-align: center;
            h1 {
                color: #7e7e7e;
                margin-bottom: 2px;
            }
            p {
                color: #5a5a5a;
                font-size: 18px;
            }
        }
        &-body {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 50%;
            margin: 18px auto;
            min-width: 200px;
            & > .signIn {
                &-text {
                    color: #393939;
                    font-size: 20px;
                    margin-bottom: 14px;
                }
                &-btn {
                    background: #0078d7;
                    color: white;
                    height: 35px;
                    font-size: 17px;
                    border: 1px solid transparent;
                    cursor: pointer;
                    outline: none;
                    margin-bottom: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                &-dont {
                    color: #231f20;
                    font-size: 14px;
                    text-align: center;
                    & > .signUpLink {
                        color: #0078d7;
                        font-size: 15px !important;
                        cursor: pointer;
                    }
                }
            }
            &_field {
                height: 35px;
                border: 1px solid #a8a8a8;
                outline: none;
                font-size: 15px;
                color: #5a5a5a;
                padding: 0 8px;
                margin-bottom: 12px;
            }
            &_OR {
                overflow: hidden;
                text-align: center;
                color: #666666;
                font-size: 18px;
                margin: 24px 0;
                &::before,
                &::after {
                    background-color: #515151;
                    content: "";
                    display: inline-block;
                    height: 1.2px;
                    position: relative;
                    vertical-align: middle;
                    width: 50%;
                }
                &::before {
                    right: 0.5em;
                    margin-left: -50%;
                }
                &::after {
                    left: 0.5em;
                    margin-right: -50%;
                }
            }
            &_microsoft-login {
                border: 1.5px solid #717171;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 38px;
                font-size: 17px;
                color: #2f2f2f;
                cursor: pointer;
                img {
                    height: 22px;
                }
                span {
                    margin-left: 10px;
                }
            }
        }
    }
}
